import styled from "styled-components";

function Media({icon}) {
    return(
        <Wrapper>
            <Icon><img src={icon}/></Icon>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
`

const Icon = styled.div`
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    background:  #FFF;
`

export default Media