import styles from "./styles.module.css"
import Container from "../container";
import Button from "../button";
import Input from "../input";

function Form() {
    return (
        <Container>
            <div className={styles.form}>
                <Input label={"Origin"} icon={<Loc/>} placeholder={"Enter Location"}/>
                <Input label={"Destination"} icon={<Loc/>} placeholder={"Enter Location"}/>
                <Input label={'weight'} icon={<Bag/>} placeholder={"Weight (KG)"}/>
                <Button label={"Check Price"} size={"large"}/>
            </div>
        </Container>
    )
}

function Loc() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M2.33301 6.87855C2.33301 3.81197 4.8956 1.33337 7.99531 1.33337C11.1038 1.33337 13.6663 3.81197 13.6663 6.87855C13.6663 8.42383 13.1043 9.85846 12.1793 11.0744C11.1589 12.4157 9.90111 13.5844 8.48537 14.5017C8.16135 14.7137 7.86892 14.7297 7.51331 14.5017C6.0895 13.5844 4.83173 12.4157 3.82001 11.0744C2.89433 9.85846 2.33301 8.42383 2.33301 6.87855ZM6.12912 7.05121C6.12912 8.07851 6.96741 8.88649 7.99527 8.88649C9.0238 8.88649 9.87015 8.07851 9.87015 7.05121C9.87015 6.03191 9.0238 5.1846 7.99527 5.1846C6.96741 5.1846 6.12912 6.03191 6.12912 7.05121Z"
                  fill="#2C2D5B"/>
        </svg>
    )
}

function Bag() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M2.33301 6.87855C2.33301 3.81197 4.8956 1.33337 7.99531 1.33337C11.1038 1.33337 13.6663 3.81197 13.6663 6.87855C13.6663 8.42383 13.1043 9.85846 12.1793 11.0744C11.1589 12.4157 9.90111 13.5844 8.48537 14.5017C8.16135 14.7137 7.86892 14.7297 7.51331 14.5017C6.0895 13.5844 4.83173 12.4157 3.82001 11.0744C2.89433 9.85846 2.33301 8.42383 2.33301 6.87855ZM6.12912 7.05121C6.12912 8.07851 6.96741 8.88649 7.99527 8.88649C9.0238 8.88649 9.87015 8.07851 9.87015 7.05121C9.87015 6.03191 9.0238 5.1846 7.99527 5.1846C6.96741 5.1846 6.12912 6.03191 6.12912 7.05121Z"
                  fill="#2C2D5B"/>
        </svg>
    )
}

export default Form