import styles from "./styles.module.css"
import classNames from "classnames";

function Container({children, variant, variantTwo, variantThree}){
    return(
        <div className={classNames(styles.section, variant === "secondary" && styles.secondary, variantTwo === "three" && styles.three, variantThree === "four" && styles.four)}>
            <div className={styles.container}>
                {children}
            </div>
        </div>
    )
}
export default Container