import styles from "./styles.module.css"
import Container from "../container";
import bibika from "../../icons/bibika.svg"
import Feature from "../feature";
import icon from "../../icons/warehousing-services.svg"
import fly from "../../icons/global-freight.svg"
import bag from "../../icons/packaging-solutions.svg"
import styled from "styled-components";
import Button from "../button";

function Service() {
    return (
        <Container variant={"secondary"}>
            <div className={styles.wrapper}>
                <div className={styles.titleWrapper}>
                    <h2 className={styles.title}><span className={styles.span}>Services</span> We Offer</h2>
                    <img src={bibika}/>
                </div>
                <Features>
                    <Feature icon={icon} title={"Warehousing Services"} description={"A pay as-you-go solution for: pallet storage, inventory management, fulfillment(e.g. pick and pack), in/out-bound solutions, and more."}/>
                    <Feature icon={fly} title={"Global Freight"} description={"Search and compare the best shipping rates among dozens of trusted logistic partners for the last mile delivery and freight."}/>
                    <Feature icon={bag} title={"Packaging Solutions"} description={"Our packaging solutions are optimized for each individual customer and are selected based on on the customer’s specific needs and requirements."}/>
                </Features>
                <Buttons>
                    <Button variant={"secondary"} label={"Request Quote"}/>
                    <Button label={"Join Now"}/>
                </Buttons>
            </div>
        </Container>
    )
}

const Features = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 76px;
`

const Buttons = styled.div`
    display: flex;
    align-items: center;
    align-self: center;
    gap: 30px;
`
export default Service