import styled from "styled-components";

function Step({number, title, description, img, reversed}) {
    return (
        <Wrapper reversed={reversed}>
            <WrapperTwo>
                <TitleWrapper>
                    <Number children={number}/>
                    <Title children={title}/>
                </TitleWrapper>
                <Description children={description}/>
            </WrapperTwo>
                <Image src={img}/>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: ${(props) => props.reversed ? 'row-reverse' : "row"};
    margin-right: ${(props) => props.reversed && '100px' };
    margin-left: ${(props) => !props.reversed && '100px' };
    justify-content: space-between;
`
const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 30px;
`
const WrapperTwo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
    justify-content: center;
`

const Number = styled.div`
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    background:  #230B34;
    color: #FFFFFF;
    font-size: 24px;
    font-weight: 700;
`
const Title = styled.h3`
    color:  #F67366;
    font-size: 40px;
    font-weight: 900;
    text-transform: capitalize;
`

const Description = styled.p`
    height: 40px;
    width: 300px;
    color: #4F4F4F;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    transition: color .3s;
`

const Image = styled.img`
    display: flex;
`

export default Step