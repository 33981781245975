import Container from "../container";
import styles from "./styles.module.css"
import map from "../../icons/map.svg"
import Button from "../button";
import styled from "styled-components";
function Map () {
    return(
        <Container variantTwo={"three"}>
            <div className={styles.wrapper}>
                <h3 className={styles.title}>Warehouse <span className={styles.span}>Onsite</span></h3>
            </div>
            <img className={styles.img} src={map}/>
            <Buttons>
                <Button label={"Request Quote"} variant={"secondary"}/>
                <Button label={"Join Now"}/>
            </Buttons>
        </Container>
    )
}

const Buttons = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    padding-bottom: 100px;
`

export default Map