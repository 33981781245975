import Container from "../container";
import styles from "./styles.module.css"
import bibika from "../../icons/bibika.svg";
import Step from "../steps";
import boy from "../../icons/boy.svg";
import girl from "../../icons/girl.svg";
import skuter from "../../icons/skuter.svg";
import styled from "styled-components";
import icon2 from "../../icons/icon2.svg";
import icon3 from "../../icons/icon3.svg";
function Mode() {
    return(
        <Container>
            <div className={styles.wrapper}>
                <div className={styles.titleWrapper}>
                    <h2 className={styles.title}>Operation <span className={styles.span}>Mode</span></h2>
                    <img className={styles.car} src={bibika}/>
                </div>
                <Step number={"1"} title={"Connect"}
                      description={"You’re currently running your store on Shopify, WooCommerce, or any other platform. As a first step, you’ll connect your store with our platform."}
                      img={boy}/>
                <img className={styles.img} src={icon2}/>
                <Step number={"2"} title={"Store"}
                      description={"Then, you can send us your inventory and the fun begins. We’ll choose a delivery day together so your fulfilment is not interrupted."}
                      img={girl} reversed={true}/>
                <img className={styles.img1} src={icon3}/>
                <Step number={"3"} title={"Ship"}
                      description={"We pick, pack and ship all incoming orders directly from our own warehouse until 12pm on the same day."}
                      img={skuter}/>
            </div>
        </Container>
    )
}


export default Mode