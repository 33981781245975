import Container from "../container";
import styles from "./styles.module.css"
import styled from "styled-components";
import Media from "../media";
import facebook from "../../icons/facebook.svg"
import twitter from "../../icons/twitter.svg"
import whatsapp from "../../icons/whatsapp.svg"
import insta from "../../icons/insta.svg"

function Footer() {
    return (
        <Container variantThree={"four"}>
            <div className={styles.footer}>
                <FooterWrapper>
                    <div className={styles.wrapper}>
                        <h3 className={styles.title}><span className={styles.span}>Ship</span>Up</h3>
                        <p className={styles.description}>ShipUp delivers an unparalleled customer service through
                            dedicated customer teams, engaged people working in an agile culture, and a global
                            footprint</p>
                    </div>
                    <div className={styles.wrapper}>
                        <h3 className={styles.titles}>Explore</h3>
                        <div className={styles.paragraph}>
                            <p>About Us</p>
                            <p>Our Warehouses</p>
                            <p>Blog</p>
                            <p>New and Media</p>
                        </div>
                    </div>
                    <div className={styles.wrapper}>
                        <h3 className={styles.titles}>Legal</h3>
                        <div className={styles.paragraph}>
                            <p>Terms</p>
                            <p>Privacy</p>
                        </div>
                    </div>
                    <div className={styles.wrapper}>
                        <h3 className={styles.titles}>Social Media</h3>
                        <div className={styles.Media}>
                            <Media icon={facebook}/>
                            <Media icon={twitter}/>
                            <Media icon={whatsapp}/>
                            <Media icon={insta}/>
                        </div>
                    </div>
                </FooterWrapper>
                <div className={styles.divider}/>
                <div className={styles.logo}>
                    <p>Ship<span>Up</span><span>.ng</span></p>
                </div>
            </div>
        </Container>
    )
}

const FooterWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
`

export default Footer