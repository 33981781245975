import styled from "styled-components";
function Feature({icon, title, description }) {
    return(
        <Wrapper>
            <Icon><img src={icon}/></Icon>
            <Title children={title}/>
            <Description children={description}/>
        </Wrapper>
    )
}

const Wrapper = styled.div` 
    display: flex;
    flex-direction: column;
    gap: 15px;
    flex: 1;
`

const Title = styled.h3`
    color:  #2C2D5B;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
`
const Description = styled.p`
    color:  #4F4F4F;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; 
`
const Icon = styled.div`
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    background:  #FFF;
    box-shadow: 0px 0px 25px 0px rgba(151, 151, 151, 0.25);
`
export default Feature