import styles from "./styles.module.css"
import classNames from "classnames";

function Button({variant, label, size}) {
    console.log(variant === "secondary")
    return(
        <button className={classNames(styles.button, variant === "secondary" && styles.buttonSecondary, styles[size])}>{label}</button>
    )
}

export default Button