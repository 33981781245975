import styles from "./styles.module.css"
import Container from "../container";
import Button from "../button";

function NavBar() {
    return (
        <Container>
            <div className={styles.navBar}>
                <p className={styles.title}>Ship<span className={styles.span}>Up</span></p>
                <div className={styles.links}>
                    <a className={styles.link} href="#"><p>Company</p> <Chevron/></a>
                    <a className={styles.link} href="#"><p>Services</p> <Chevron/></a>
                    <a className={styles.link} href="#">Solution</a>
                    <a className={styles.link} href="#">Industries</a>
                    <a className={styles.link} href="#">Insights</a>
                    <a className={styles.link} href="#">News and Media</a>
                </div>
                <div className={styles.buttons}>
                    <Button label={"Request Quote"} variant={"secondary"}/>
                    <Button label={"Join Now"}/>
                </div>
            </div>
        </Container>
    )
}

function Chevron() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
            <path d="M7.41 9.07999L12 13.67L16.59 9.07999L18 10.5L12 16.5L6 10.5L7.41 9.07999Z" fill="#4F4F4F"/>
        </svg>
    )
}


export default NavBar