import styles from "./styles.module.css"

function Input({placeholder, icon, label}) {
    return(
        <div className={styles.wrapper}>
            <span className={styles.label} children={label} />
            {icon}
            <input placeholder={placeholder}/>
        </div>
    )
}

export default Input