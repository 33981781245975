import Container from "../container";
import styles from "./styles.module.css"
import Button from "../button";
import girl from "../../img/girl.png";
import  icon1 from "../../icons/icon1.svg"
function Header() {
    return(
        <Container>
            <div className={styles.header}>
                <div className={styles.block}>
                    <h1 className={styles.title}>Quick & Reliable <span className={styles.span}>Warehousing</span><br/>
                        <span className={styles.span}>and Logistics</span> Solution.</h1>
                    <p className={styles.paragraf}>ShipUp delivers an unparalleled customer service through dedicated
                        customer teams, engaged <br/> people working in an agile culture, and a global footprint</p>
                    <div className={styles.button}>
                        <Button label={"Join Now"}/>
                        <div className={styles.demo}>
                            <div className={styles.circle}><Video/></div>
                            <p className={styles.play}>Play Demo</p>
                        </div>
                    </div>
                </div>
                <div className={styles.girl}>
                    <Girl/>
                </div>
                <img className={styles.img} src={icon1}/>
            </div>
        </Container>
    )
}

function Video() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M7.78945 5.94995H14.3924C17.1535 5.94995 19.0813 7.85265 19.0813 10.5794V18.4205C19.0813 21.1472 17.1535 23.0499 14.3924 23.0499H7.78945C5.02835 23.0499 3.10059 21.1472 3.10059 18.4205V10.5794C3.10059 7.85265 5.02835 5.94995 7.78945 5.94995ZM23.5727 8.66199C24.0731 8.40737 24.6602 8.43399 25.1379 8.73491C25.6156 9.03466 25.9006 9.55547 25.9006 10.1249V18.8757C25.9006 19.4463 25.6156 19.9659 25.1379 20.2657C24.8768 20.4289 24.585 20.5122 24.2909 20.5122C24.0458 20.5122 23.8007 20.4543 23.5715 20.3374L21.8832 19.4856C21.2584 19.1685 20.8708 18.532 20.8708 17.8248V11.1746C20.8708 10.4663 21.2584 9.82977 21.8832 9.51497L23.5727 8.66199Z"
                  fill="#2C2D5B"/>
        </svg>
    )
}

function Girl() {
    return (
        <div>
            <img src={girl} alt="картинка"/>
        </div>
    )
}
export default Header