import './App.css';
import NavBar from "./components/nav-bar";
import Header from "./components/Header";
import Form from "./components/form-cost";
import Service from "./components/services";
import Mode from "./components/operation-mode";
import Map from "./components/map";
import Footer from "./components/footer";

function App() {
  return (
    <div className="App">
      <NavBar/>
        <Header/>
        <Form/>
      <Service/>
        <Mode/>
        <Map/>
        <Footer/>
    </div>
  );
}

export default App;
